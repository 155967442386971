.contact-board {
  width: 90%;
  max-width: 130rem;
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
  margin: 10rem 0;

  @media screen and (max-width: 75em) {
    flex-direction: column;
    justify-content: center;
  }

  &__left-column {
    text-align: left;
    padding: 2rem 1rem 2rem 0;
    background-color: #f3f3f3;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.15);
    width: 50rem;

    @media screen and (max-width: 75em) {
      width: 55rem;
      align-self: center;
      padding-right: 0;
    }

    @media screen and (max-width: 37.5em) {
      width: 95%;
    }
  }

  &__title {
    padding: 0 3rem;
  }

  &__right-column {
    max-width: 55rem;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 75em) {
      width: 95%;
      margin-top: 10rem;
      align-self: center;
    }
  }

  &__contact-details {
  }

  &__contact-element {
    color: rgb(31, 30, 30);
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }

  &__social-networks-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 24rem;
    margin-top: 1.5rem;
  }

  &__social-network-link {
    color: rgb(36, 36, 36);
    font-size: 2.9rem;

    &:hover {
      transition: all 0.4s ease-in-out;
      transform: scale(1.2);
      color: rgb(68, 68, 68);
    }
  }

  &__google-maps {
    position: relative;
    width: 40rem;
    height: 50rem;

    @media screen and (max-width: 75em) {
      width: 100%;
      margin-top: 5rem;
      align-self: center;
    }
  }
}
