.heading-primary {
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  font-weight: 300;
  font-size: 3.5rem;
  text-align: center;
  letter-spacing: 0.2rem;
  word-spacing: 0.5rem;

  &--black {
    color: rgb(0, 0, 0);
  }

  &--white {
    color: rgb(245, 240, 240);
  }
}

.heading-secondary {
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  color: rgb(245, 240, 240);
  font-weight: 300;
  font-size: 2.5rem;
  text-align: center;
  letter-spacing: 0.2rem;
  word-spacing: 0.5rem;

  &--black {
    color: rgb(39, 39, 39);
  }

  &--white {
    color: rgb(245, 240, 240);
  }
}
