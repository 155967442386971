.form {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.form-group {
  padding: 1.5rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.double-input {
  width: 98%;

  display: grid;
  column-gap: 0.6rem;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: 1fr auto auto;
}

.label {
  color: rgb(36, 36, 36);
  font-size: 1.7rem;
  text-align: left;

  &--white {
    color: rgb(199, 199, 199);
  }
}

.sub-label {
  color: rgb(36, 36, 36);
  font-size: 1.3rem;
}

.select-input {
  margin-top: 1rem;
  width: 40%;
  font-size: 1.7rem;

  @media screen and (max-width: 75em) {
    width: 50%;
  }

  @media screen and (max-width: 56.25em) {
    width: 70%;
  }

  @media screen and (max-width: 37.5em) {
    width: 80%;
  }
}

.field-name {
  padding-top: 0.7rem;
  color: rgb(19, 19, 19);
}

.input {
  width: 98%;
  padding: 1.2rem 0.3rem;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  resize: vertical;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1.7rem;
  color: rgb(48, 48, 48);

  &--white-border {
    border-bottom: 1px solid rgb(216, 216, 216);
  }

  &:hover {
    border-bottom: 1px solid $color-red-light;
    transition: 0.8s ease;
  }
}

.text-area {
  width: 100%;
  padding: 1.2rem 0.3rem;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  height: 15rem;
  background-color: transparent;
  font-size: 1.9rem;
  color: rgb(39, 39, 39);

  &:hover {
    border-bottom: 1px solid $color-red-light;
    transition: 0.8s ease;
  }
}

.error {
  display: flex;
  align-items: center;
  margin-top: 0.6rem;
}

.error-message {
  display: flex;
  align-items: center;
  color: $color-red;
  border-radius: 6px;
  height: 100%;
  font-size: 1.4rem;
}

.error-icon {
  width: 1.7rem;
  margin-right: 0.5rem;
}
