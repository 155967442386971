.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
  background-color: rgb(245, 240, 240);
  white-space: nowrap;
  padding: 1.8rem 5.8rem;
  margin-top: 2.5rem;
  color: #010606;
  font-size: 1.6rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &--white {
    margin: 2rem 0;
    background: rgba(1, 1, 1, 0);
    border: 1px solid rgb(240, 239, 239);
    color: rgb(241, 241, 241);
    width: 16rem;
    height: 5.5rem;
    border-radius: 2rem;
    &:hover {
      border: 1px solid $color-red-light;
      color: $color-red-light;
    }
  }

  &--black {
    margin: 2rem 0;
    background: rgba(1, 1, 1, 0);
    border: 1px solid rgb(19, 19, 19);
    color: rgb(26, 25, 25);
    width: 16rem;
    height: 5.5rem;
    border-radius: 2rem;
    &:hover {
      border: 1px solid $color-red-light;
      color: $color-red-light;
      transform: scale(1.02);
    }
  }

  &__font {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;
    position: relative;
  }

  &--contact {
    width: 16rem;
    &:hover {
      transition: all 0.3s ease-in-out;
      transform: scale(1.07);
    }
  }
}

.my-class-name {
  font-size: 2rem;
}
