.footer {
  background-color: rgb(12, 12, 12);
  padding: 5rem 20vw 5rem 20vw;
  position: relative;
  z-index: 1;

  &__container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 120rem;
  }

  &__links {
    display: flex;
    flex-direction: column;
  }

  &__link-title {
    font-size: 2rem;
    margin-bottom: 1.6rem;
    color: rgb(245, 240, 240);
  }

  &__link {
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
    color: rgb(245, 240, 240);
    cursor: pointer;

    &:hover {
      transition: all 0.3s ease-in-out;
      color: $color-red;
    }
  }

  &__website-rights {
    color: rgb(245, 240, 240);
    align-self: center;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 75rem) {
    padding: 5rem 15vw 5rem 15vw;
  }

  @media screen and (max-width: 37.5rem) {
    padding: 5rem 5vw 5rem 5vw;
  }
}
