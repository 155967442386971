/* Hide the browser's default checkbox */
.checkbox-item {
    display: block;
    position: relative;
    padding-left: 3rem;
    margin-bottom: .6rem;
    cursor: pointer;
    font-size: 1.5rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

     /* Create a custom checkbox */
     &__checkmark {
        border-radius: 20%;
        position: absolute;
        top: 0;
        left: 0;
        height: 2rem;
        width: 2rem;
        background-color: #eee;
        transition: all ease .4s;
        
    }
      
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-item:hover input ~ .checkbox-item__checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-item input:checked ~ .checkbox-item__checkmark {
    background-color: #d81111;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkbox-item__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the heckmark when checked */
  .checkbox-item input:checked ~ .checkbox-item__checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-item .checkbox-item__checkmark:after {
    left: .7rem;
    top: .5rem;
    width: .6rem;
    height: 1rem;
    border: solid white;
    border-width: 0 .3rem .3rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }