.application {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 19rem;
  position: relative;
  z-index: 1;

  &__background {
    background-color: rgb(223, 223, 223);
    background-image: url(../../images/bg-img3.jpg);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  &__title {
    z-index: 2;
  }
}
