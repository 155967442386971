.home {
}

.hero-section {
  background: #0c0c0c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.22);
  }

  &__background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  &__background-image {
    width: 100vw;
    height: 100vh;
    -o-object-fit: cover;
    object-fit: cover;
  }

  &__personal-training-certificate {
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding-right: 2rem;
    position: absolute;
    width: 100%;
    bottom: 2rem;
    z-index: 3;

    @media screen and (max-width: 37.5rem) {
      margin-top: auto;
      position: relative;
      justify-content: center;
    }
  }

  &__certification-image {
    width: 35rem;
    height: 10rem;
  }
}
