.navbar {
  background: #000;
  height: 9rem;
  margin-top: -9rem;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: all 0.5s ease-in-out;

  &--active {
    background: transparent;
  }

  @media screen and (max-width: 75em) {
    transition: 0.8s all ease;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 9rem;
    z-index: 1;
    width: 100%;
    padding: 0 2.4rem;
  }

  &__logo {
    cursor: pointer;
  }

  &__mobile-icon {
    display: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @media screen and (max-width: 56.25em) {
      display: block;
      height: 9rem;
      display: flex;
      align-items: center;
      font-size: 1.9rem;
      cursor: pointer;
      color: rgb(255, 255, 255);
    }

    &:hover {
      color: $color-red;
      transition: 0.2s ease-in-out;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;

    @media screen and (max-width: 56.25em) {
      display: none;
    }
  }

  &__item {
    height: 8rem;
  }

  &__link {
    font-size: 1.9rem;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
      border-bottom: 3px solid $color-red;
    }

    &:hover {
      transition: all 0.3s ease-in-out;
      color: $color-red;
    }
  }

  .transparent-nav {
    background: transparent;
  }
}
