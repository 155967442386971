.sidebar {
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0);
  top: -100%;

  &--active {
    background-color: rgb(0, 0, 0);
    top: 0;
  }

  &__icon-container {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  &__close-icon {
    color: #fff;

    &:hover {
      color: $color-red;
      transition: 0.2s ease-in-out;
    }
  }

  &__container {
    color: #fff;
  }

  &__menu {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 8rem);
    text-align: center;

    @media screen and (max-width: 37.5em) {
      grid-template-rows: repeat(6, 6rem);
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;

    &:hover {
      color: $color-red;
      transition: 0.2s ease-in-out;
    }
  }
}
