.profile {
  max-width: 120rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;

  @media screen and (max-width: 37.5em) {
    margin-top: auto;
  }

  &__name {
    font-weight: 300;
    color: rgb(245, 240, 240);
    font-size: 3.8rem;
    text-align: center;
    line-height: 6rem;
    letter-spacing: 0.3rem;
  }

  &__profile-image {
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
  }

  &__characteristics {
    margin-top: 2.4rem;
    color: rgb(245, 240, 240);
    font-size: 2.8rem;
    text-align: center;
    max-width: 60rem;

    @media screen and (max-width: 37.5em) {
      font-size: 2.3rem;
    }
  }
}
