.contact {
  background-color: rgb(243, 243, 243);
  padding-top: 19rem;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
