.application-form {
  margin: 7rem 0;
  width: 50%;
  max-width: 95rem;
  height: auto;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(235, 235, 235, 0.514);
  border-radius: 10px;
  position: relative;
  z-index: 1;
  padding: 2rem 0;

  @media screen and (max-width: 90em) {
    width: 75%;
  }

  @media screen and (max-width: 75em) {
    width: 75%;
  }

  @media screen and (max-width: 56.25em) {
    width: 80%;
  }

  @media screen and (max-width: 37.5em) {
    width: 90%;
  }
}
