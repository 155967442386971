.about {
  padding-top: 19rem;
  position: relative;
  z-index: 1;
  background-color: rgb(243, 243, 243);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personal-statement {
  max-width: 100rem;
  color: rgb(38, 38, 38);
  padding-top: 7rem;
  font-weight: 300;
  align-self: center;
  font-size: 1.8rem;
  letter-spacing: 0.2rem;
  margin-bottom: 10rem;

  @media screen and (max-width: 56.25em) {
    width: 90%;
  }

  @media screen and (max-width: 37.5rem) {
    font-size: 1.6rem;
  }

  p {
    margin-bottom: 2.5rem;
  }
}
